import React from 'react'
import { Container, Row, Col, Stack } from 'react-bootstrap';

export const XRUses = () => {
    return (
        <>
            <Container data-aos='fade-up '>
                <div className="CollabContainer">
                    <Row className="justify-content-center g-3">
                        <Col lg={3} md={6} xs={12}>
                            <div className="CollabBox p-3">
                                <Stack direction='vertical' className='align-items-center  justify-content-center'>
                                    <h3 className='text-primary fw-bold'>Holoportation</h3>
                                    <p> 3D representations of remote members</p></Stack>
                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={12}>
                            <div className="CollabBox p-3">
                                <Stack direction='vertical' className='align-items-center  justify-content-center'>
                                    <h3 className='text-primary fw-bold'>Showroom XR</h3>
                                    <p>Browse a car showroom from anywhere</p>
                                </Stack>
                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={12}>
                            <div className="CollabBox p-3">
                                <Stack direction='vertical' className='align-items-center  justify-content-center'>
                                    <h3 className='text-primary fw-bold'>Microsoft Mesh</h3>
                                    <p> Collaborative virtual meetings and even </p></Stack>
                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={12}>
                            <div className="CollabBox p-3">
                                <Stack direction='vertical' className='align-items-center  justify-content-center'>
                                    <h3 className='text-primary fw-bold'>Mayo Clinic</h3>
                                    <p>Enhance medical training and patient care</p></Stack>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    )
}
