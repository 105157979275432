import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const AboutARVR = () => {
    return (
        <>
            <Container className='my-5' data-aos="fade-up">
                <div className="AboutUs02">
                    <Row className="justify-content-center">
                        <Col md={12} lg={9} sm={12}>
                            <div className="AboutUsText">
                                <div className="vstack gap-3">
                                    <h1 className="text-center fontWeight-800">Delight Your Audience. Redefine Business and Interaction.</h1>
                                    <h4 className="text-center">Let your imagination set the stage with custom AR/VR solutions. </h4>
                                    <p className="text-center fontSize-24 text-grey-500 fontWeight-500">
                                    Design your bespoke augmented and virtual reality solution, complete with individual features such as interactive modules, simulations, and seamless integrations. With AR/VR applications, you can bring your ideas to life—be it through immersive training programs, virtual product demonstrations, and much more. These technologies bridge the gap between the physical and digital worlds, providing immersive experiences that excite and inspire. With the proliferation of <Link to="/immersive-technologies-services-and-solutions-ar-vr-mr-xr/extended-reality-development-services-and-solutions-xr" target="_Blank">extended reality app development solutions</Link>, companies are staying ahead of the curve and opening up new opportunities for growth and interaction. Are you ready to rethink the way you communicate?
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    )
}
