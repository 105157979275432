import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const service1 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/ImmersiveTech/MRService-01.png'
const service2 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/ImmersiveTech/MRService-02.png'
const service3 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/ImmersiveTech/MRService-03.png'
const service4 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/ImmersiveTech/MRService-04.png'
const service5 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/ImmersiveTech/MRService-05.png'
const service6 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/ImmersiveTech/MRService06.png' 


export const MRService = () => {
    return (
        <>
            <Container className='my-5' data-aos="fade-up">
                <Row>
                    <Col>
                        <h1 className="fontWeight-800">
                        Mixed Reality Development Services & Solutions
                        </h1>
                        <p className="text-center text-grey-500 fontSize-20">Futurristic offers the ideal <Link to="/" target="_Blank">Mixed Reality development service and solutions</Link> your business needs. Explore now.</p>
                    </Col>
                </Row>

                <Row className="g-4">
                    <Col sm={12} md={4} lg={4} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src={service1} className="img-fluid serviceIcon" alt="MRService-01-futurristic" />
                                <h5 className="fontWeight-800 text-center">MR Consultation & Strategy Services </h5>
                                <p className="
                                text-grey-500">Work with the state-of-the-art tech team to strategize the best, most suitable solutions for your business. Consult based on your business problems, needs, and future goals.
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                    <Col sm={12} md={4} lg={4} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src={service2} className="img-fluid serviceIcon" alt="MRService-02-futurristic" />
                                <h5 className="fontWeight-800 text-center">Mixed reality App, Software Development</h5>
                                <p className="text-justify text-grey-500">Developing cutting-edge MR apps and software, including HoloLens solutions, with a perfect blend of technology and artistry that ensure immersive, distraction-free experiences that enhance real-world interactions across industries.
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                    <Col sm={12} md={4} lg={4} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src={service3} className="img-fluid serviceIcon" alt="MRService-03-futurristic" />
                                <h5 className="fontWeight-800 text-center">Mixed Reality Game, Portal Development</h5>
                                <p className="text-justify text-grey-500">Experience an immersive Mixed Reality game where physical movement drives the action. Mixed Reality Portals offer interactive 3D environments for seamless navigation, customization and integration of virtual workspaces.
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>

                    <Col sm={12} md={4} lg={4} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src={service4} className="img-fluid serviceIcon" alt="MRService-04-futurristic" />
                                <h5 className="fontWeight-800 text-center">Industry-Specific Mixed Reality Solutions</h5>
                                <p className="text-justify text-grey-500">Customized mixed reality solutions designed to the specific needs of your industry enhance workflows, training, and customer experiences with immersive, tailored applications for seamless integration.
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col >
                    <Col sm={12} md={4} lg={4} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src={service5} className="img-fluid serviceIcon" alt="MRService-05-futurristic" />
                                <h5 className="fontWeight-800 text-center">Mixed Reality Product Configurations </h5>
                                <p className="text-justify text-grey-500">Customize, visualize, and interact with products in real-time with mixed reality. Improve decision-making with immersive 3D configurations for a seamless and dynamic experience.
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                    <Col sm={12} md={4} lg={4} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src={service6} className="img-fluid serviceIcon" alt="MRService-06-futurristic" />
                                <h5 className="fontWeight-800 text-center">Mixed Reality Customization Services</h5>
                                <p className="text-justify text-grey-500">
                                Bespoke mixed reality solutions tailored to specific business needs, ensuring seamless integration, enhanced interactivity, and a fully customized experience for optimal functionality and engagement.
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
