import React from 'react'
import { Container, Row, Col, Stack } from 'react-bootstrap';
const benefit1 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/Icons/ARVRTraining-benefit01.png'
const benefit2 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/Icons/ARVRTraining-benefit02.png'
const benefit3 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/Icons/ARVRTraining-benefit03.png'
const benefit4 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/Icons/ARVRTraining-benefit04.png'
const benefit5 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/Icons/ARVRTraining-benefit05.png'
const benefit6 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/Icons/ARVRTraining-benefit06.png'


export const BenefitARVR = () => {
    return (
        <>
            <Container className="my-5" data-aos="fade-up">
                <Row>
                    <Col >
                        <h1 className="text-center fontWeight-800">
                        Here are the Potential Benefits of Augmented & Virtual Reality (AR VR) Development Services
                        </h1>
                        <p className="text-center text-grey-500 fontSize-20 ">
                        Explore how Augmented & Virtual Reality Services & Solutions redefine engagement and innovation.
                        </p>
                    </Col>
                </Row>
                <Row className="g-3">
                    <Col md={4} xs={6} lg={2}>
                        <div className="BenefitContent1">
                            <img src={benefit1} className="img-fluid benefitIcons" alt="ARVRBenefit-01-futurristic" />
                            <Stack direction='horizontal' className='align-items-center justify-content-center h-50'><p>Enhanced User Engagement </p></Stack>
                        </div>
                    </Col>
                    <Col md={4} xs={6} lg={2}>
                        <div className="BenefitContent2">
                            <img src={benefit2} className="img-fluid benefitIcons" alt="ARVRBenefit-02-futurristic" />
                            <Stack direction='horizontal' className='align-items-center justify-content-center h-50'><p>Increased Customer Retention</p></Stack>
                        </div>
                    </Col>
                    <Col md={4} xs={6} lg={2}>
                        <div className="BenefitContent1">
                            <img src={benefit3} className="img-fluid benefitIcons" alt="ARVRBenefit-03-futurristic" />
                            <Stack direction='horizontal' className='align-items-center justify-content-center h-50'><p>Interactive Virtual Environment</p></Stack>
                        </div>
                    </Col>
                    <Col md={4} xs={6} lg={2}>
                        <div className="BenefitContent2">
                            <img src={benefit4} className="img-fluid benefitIcons" alt="ARVRBenefit-04-futurristic" />
                            <Stack direction='horizontal' className='align-items-center justify-content-center h-50'><p>Real-World Simulations</p></Stack>
                        </div>
                    </Col>
                    <Col md={4} xs={6} lg={2}>
                        <div className="BenefitContent1">
                            <img src={benefit5} className="img-fluid benefitIcons" alt="ARVRBenefit-05-futurristic" />
                            <Stack direction='horizontal' className='align-items-center justify-content-center h-50'><p>Cost-Effective Solution </p></Stack>
                        </div>
                    </Col>
                    <Col md={4} xs={6} lg={2}>
                        <div className="BenefitContent2">
                            <img src={benefit6} className="img-fluid benefitIcons" alt="ARVRBenefit-06-futurristic" />
                            <Stack direction='horizontal' className='align-items-center justify-content-center h-50'><p>Customer-Centric Innovation</p></Stack>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
