import React from 'react'
import { Container, Row, Col, Stack } from 'react-bootstrap';

export const ARVRUses = () => {
  return (
    <>
      {/* <Container className="my-5">
               
                <Row className="g-3">
                    <Col lg={3} md={2} sm={2}>
                        <div className='p-3 h-100 shadow'>
                            <Stack direction='vertical' className='align-items-center  justify-content-center'>
                                <h3 className='text-primary fw-bold'>Snapchat Filters </h3>
                                <p>Enhances photos/videos</p></Stack>
                        </div>
                    </Col>
                    <Col lg={3} md={2} sm={2}>
                    <div className='p-3 h-100 shadow'>
                            <Stack direction='vertical' className='align-items-center  justify-content-center'>
                                <h3 className='text-primary fw-bold'>Pokemon Go</h3>
                                <p>real-world locations with virtual elements</p>
                                </Stack>
                        </div>
                    </Col>
                    <Col lg={3} md={2} sm={2}>
                    <div className='p-3 h-100 shadow'>
                            <Stack direction='vertical' className='align-items-center  justify-content-center'>
                              <h3 className='text-primary fw-bold'>IKEA Place </h3>
                                <p>visualize furniture in their own home</p></Stack>
                        </div>
                    </Col>
                    <Col lg={3} md={2} sm={2}>
                    <div className='p-3 h-100 shadow'>
                            <Stack direction='vertical' className='align-items-center  justify-content-center'>
                                <h3 className='text-primary fw-bold'>Google Lens</h3>
                                <p>Identifies objects, provides information</p></Stack>
                        </div>
                    </Col>
                </Row>
            </Container> */}

      <Container data-aos='fade-up '>
        <div className="CollabContainer">
          <Row className="justify-content-center g-3">
            <Col lg={12} md={12} sm={12}><h2 className='fontWeight-600'>Uses Of Augmented Reality</h2></Col>
            <Col lg={3} md={6} xs={12}>
              <div className="CollabBox p-3">
                <Stack direction='vertical' className='align-items-center  justify-content-center'>
                  <h3 className='text-primary fw-bold'>Snapchat Filters </h3>
                  <p>Enhances photos/videos through AR lens </p></Stack>
              </div>
            </Col>
            <Col lg={3} md={6} xs={12}>
              <div className="CollabBox p-3">
                <Stack direction='vertical' className='align-items-center  justify-content-center'>
                  <h3 className='text-primary fw-bold'>Pokemon Go</h3>
                  <p>Real-world locations with virtual elements</p>
                </Stack>
              </div>
            </Col>
            <Col lg={3} md={6} xs={12}>
              <div className="CollabBox p-3">
                <Stack direction='vertical' className='align-items-center  justify-content-center'>
                  <h3 className='text-primary fw-bold'>IKEA Place </h3>
                  <p>Visualize furniture in their own home</p></Stack>
              </div>
            </Col>
            <Col lg={3} md={6} xs={12}>
              <div className="CollabBox p-3">
                <Stack direction='vertical' className='align-items-center  justify-content-center'>
                  <h3 className='text-primary fw-bold'>Google Lens</h3>
                  <p>Identifies objects, provides information</p></Stack>
              </div>
            </Col>

            <Col lg={12} md={12} sm={12}><h2 className='mt-3 fontWeight-600'>Uses Of Virtual Reality</h2></Col>

            <Col lg={3} md={6} xs={12}>
              <div className="CollabBox p-3">
                <Stack direction='vertical' className='align-items-center  justify-content-center'>
                  <h3 className='text-primary fw-bold'>Beat Saber</h3>
                  <p>Rhythm game using motion controllers</p></Stack>
              </div>
            </Col>
            <Col lg={3} md={6} xs={12}>
              <div className="CollabBox p-3">
                <Stack direction='vertical' className='align-items-center  justify-content-center'>
                  <h3 className='text-primary fw-bold'>Oculus Venues</h3>
                  <p>Attend any shows, events, concerts, etc.</p>
                </Stack>
              </div>
            </Col>
            <Col lg={3} md={6} xs={12}>
              <div className="CollabBox p-3">
                <Stack direction='vertical' className='align-items-center  justify-content-center'>
                  <h3 className='text-primary fw-bold'>Osso VR</h3>
                  <p>Visualize furniture in their own home</p></Stack>
              </div>
            </Col>
            <Col lg={3} md={6} xs={12}>
              <div className="CollabBox p-3">
                <Stack direction='vertical' className='align-items-center  justify-content-center'>
                  <h3 className='text-primary fw-bold'>Google Earth VR</h3>
                  <p>Experience the world in immersive 3D views</p></Stack>
              </div>
            </Col>

          </Row>
        </div>
      </Container>
    </>
  )
}
