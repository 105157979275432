import React from 'react'
import { Container, Row, Col, Stack } from 'react-bootstrap';

export const MRUses = () => {
    return (
        <>
            <Container data-aos='fade-up '>
                <div className="CollabContainer">
                    <Row className="justify-content-center g-3">
                        <Col lg={3} md={6} xs={12}>
                            <div className="CollabBox p-3">
                                <Stack direction='vertical' className='align-items-center  justify-content-center'>
                                    <h3 className='text-primary fw-bold'>Microsoft HoloLens 2</h3>
                                    <p>Interact with highly realistic 3D anatomical models</p></Stack>
                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={12}>
                            <div className="CollabBox p-3">
                                <Stack direction='vertical' className='align-items-center  justify-content-center'>
                                    <h3 className='text-primary fw-bold'>Magic Leap</h3>
                                    <p>Collaborating on a physical prototype of a car engine </p>
                                </Stack>
                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={12}>
                            <div className="CollabBox p-3">
                                <Stack direction='vertical' className='align-items-center  justify-content-center'>
                                    <h3 className='text-primary fw-bold'>DAQRI</h3>
                                    <p>See Holographic instructions overlaid onto complex machinery </p></Stack>
                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={12}>
                            <div className="CollabBox p-3">
                                <Stack direction='vertical' className='align-items-center  justify-content-center'>
                                    <h3 className='text-primary fw-bold'>NVIDIA Omniverse</h3>
                                    <p>Create a digital twin of anything. Design & simulation</p></Stack>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    )
}
