import React, { useState } from 'react';
import { Card, Col, Container, Row, Stack } from 'react-bootstrap';
import Slider from 'react-slick';

export const MobileAppPortfolio = () => {
    const [index, setIndex] = useState(0);
    const [isHovered, setIsHovered] = useState([false, false, false, false]);
    const [setModalVideoSrc] = useState('');

    const [MobileApp] = useState([
        {
            image: 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/Game/01App-vibro.png',
            video: 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/Game/01App-vibro.mp4',
            TumbTitle: 'Music'
        },
        {
            image: 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/Game/01-Threads01.png',
            video: 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/Game/01-Threads.mp4',
            TumbTitle: 'Dating'
        },
        {
            image: 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/Game/01-Qme.png',
            video: 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/Game/01-Qme.mp4',
            TumbTitle: 'Social'
        },
        {
            image: 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/Game/02App-pet.png',
            video: 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/Game/02App-pet.mp4',
            TumbTitle: 'Pet Buddy'
        },
        {
            image: 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/ARVRMR/Walkopoly.png',
            video: 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/ARVRMR/Walkopoly.mp4',
            TumbTitle: 'Walkopoly'
        },

    ]);

   
    var settings ={
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive:[
          {
            breakpoint:480,
            settings:{
              slidesToShow: 1,
                    slidesToScroll: 1,
            }
          }
        ]
       }


    const handleMouseEnter = (cardIndex) => {
        setIsHovered(cardIndex);
    };

    const handleMouseLeave = () => {
        setIsHovered(-1);
    };

    const handleCardClick = (cardIndex, contentArray) => {
        const videoSrc = contentArray[cardIndex].video;
        setModalVideoSrc(videoSrc);
        // setShowModal(true);
    };

    return (
        <>
            <Container className='my-5'>
                <h1 className="fontWeight-800">Discover Our Dazzling Portfolio
                </h1>
                <p>Here are some Futurristic's standout projects, exemplifying innovation and excellence in every endeavor.</p>
                <Row className='p-4'>
                    {MobileApp.length > 3 ? (
                        <Slider {...settings}>
                            {MobileApp.map((card, cardIndex) => (
                                <Col key={cardIndex} className='p-2'>
                                    <Card
                                        onMouseEnter={() => handleMouseEnter(cardIndex)}
                                        onMouseLeave={handleMouseLeave}
                                        activeindex={index}
                                        className={`custom-card ${isHovered === cardIndex ? 'hovered scaleText w-20' : ''}`}
                                        onClick={() => handleCardClick(cardIndex, MobileApp)} // Added onClick handler
                                    >
                                        {isHovered === cardIndex ? (
                                            <video
                                                className="img-fluid video visible"
                                                autoPlay
                                                loop
                                                muted
                                                onEnded={() => setIndex(cardIndex)}
                                            >
                                                <source src={card.video} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        ) : (
                                            <Card.Img variant="top" src={card.image} />
                                        )}
                                        <Card.Body
                                            className={`CardBodyText02 pb-0 ${isHovered === cardIndex ? 'hidden' : 'visible'
                                                }`}
                                        >
                                            <h5>{card.TumbTitle}</h5>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Slider>
                    ) : (
                        MobileApp.map((card, cardIndex) => (
                            <Col key={cardIndex} className='p-2'>
                                <Card
                                    onMouseEnter={() => handleMouseEnter(cardIndex)}
                                    onMouseLeave={handleMouseLeave}
                                    activeindex={index}
                                    className={`custom-card ${isHovered === cardIndex ? 'hovered scaleText w-20' : ''}`}
                                    onClick={() => handleCardClick(cardIndex)} // Added onClick handler
                                >
                                    {isHovered === cardIndex ? (
                                        <video
                                            className="img-fluid video visible"
                                            autoPlay
                                            loop
                                            muted
                                            onEnded={() => setIndex(cardIndex)}
                                        >
                                            <source src={card.video} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    ) : (
                                        <Card.Img variant="top" src={card.image} />
                                    )}
                                    <Card.Body
                                        className={`CardBodyText02 p-2 ${isHovered === cardIndex ? 'hidden' : 'visible'
                                            }`}
                                    >
                                        <h5>{card.TumbTitle}</h5>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))
                    )}
                </Row>
            </Container>
        </>
    )
}
