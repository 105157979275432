import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const AboutXR = () => {
    return (
        <>
            <Container className='my-5' data-aos="fade-up">
                <div className="AboutUs02">
                    <Row className="justify-content-center">
                        <Col md={12} lg={9} sm={12}>
                            <div className="AboutUsText">
                                <div className="vstack gap-3">
                                    <h1 className="text-center fontWeight-800 mb-0">Beyond Screens: Experience the Digital Like Never Before</h1>
                                    <h2 className="text-center fontWeight-800">See. Feel. Experience. The Power of XR</h2>
                                    <h4 className="text-center">Yes! Create Immersive Experiences That Engage and Inspire </h4>
                                    <p className="text-center fontSize-24 text-grey-500 fontWeight-500">
                                    Step beyond traditional interactions and create immersive experiences with XR technologies. Whether AR for enhanced visualization, VR for full immersion or MR for dynamic integration with the real world, enterprise <Link to="https://futurristic.com/insights/the-future-of-xr-technology-in-2025-transforming-industries" target="_Blank">XR business solutions</Link> push the boundaries of engagement. Streamline training, enhance product demos and transform collaboration with next-gen spatial experiences. From conceptualization to execution, every solution is designed to improve efficiency, accuracy and interaction. Leverage advanced XR development services and bridge the gap between digitization and reality. As the demand for immersive experiences increases, <Link to="/immersive-technologies-services-and-solutions-ar-vr-mr-xr/mixed-reality-development-services-and-solutions-mr" target="_Blank">Mixed Reality App Development services</Link> can further enhance your offering and ensure you stay ahead in this rapidly evolving landscape.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    )
}
