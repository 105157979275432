import React, { useRef } from 'react'
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';
import { ARVRMRXRBanner } from './TechOverview/ARVRMRXRBanner';
import { AboutTech } from './TechOverview/AboutTech';
import { TechService } from './TechOverview/TechService';
import { TechBenefits } from './TechOverview/TechBenefits';
import { TechPortfolio } from './TechOverview/TechPortfolio';
import ContactUs from '../../../components/ContactUs/ContactUs';
import { Outlet, useLocation } from 'react-router-dom';


export const ARVRMRXRTech = () => {
  const location = useLocation();
    const portfolioRef = useRef(null);
    const contactUsRef = useRef(null);
    const scrollToRefs = {
      portfolioRef,
      contactUsRef,
    };
     
    const isRootPath = location.pathname === '/immersive-technologies-services-and-solutions-ar-vr-mr-xr';
   
  return (
     <>
     {isRootPath ? 
     (<>
      <Header scrollToRefs={scrollToRefs} portfolio={true} getInTouch={true}/>
       <ARVRMRXRBanner/>
       <AboutTech />
      <TechService />
      <TechBenefits />
      {/* <ARVRTool /> */}
      <div ref={portfolioRef}>
      <TechPortfolio />
      </div>
      <div ref={contactUsRef}>
      <ContactUs />
      </div>
      <Footer scrollToRefs={scrollToRefs}/>
     </>) :
      (<Outlet/>)
      }
     
    </>
  )
} 