import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const AboutMR = () => {
    return (
        <>
            <Container className='my-5' data-aos="fade-up">
                <div className="AboutUs02">
                    <Row className="justify-content-center">
                        <Col md={12} lg={9} sm={12}>
                            <div className="AboutUsText">
                                <div className="vstack gap-3">
                                    <h1 className="text-center fontWeight-800">Mixed Reality MR Solutions for All Industries Type </h1>
                                    <h4 className="text-center">Leverage The Next-Gen Technologies. Get The Stellar Solutions.</h4>
                                    <p className="text-center fontSize-24 text-grey-500 fontWeight-500">Mixed reality fabricates interactive environments where digital and physical worlds merge to create a seamless, immersive experience. From advanced training modules to dynamic product visualizations, MR is transforming industries with intuitive, real-time interactions. Engage customers with 3D overlays, optimize workflows with spatial computing, and enhance collaboration with mixed reality workspaces. Every experience is designed for precision, efficiency, and impact. Get the next-gen solutions tailored to the needs of the industry, seamlessly blending digital and real-world interactions. Explore more possibilities with an <Link to="/immersive-technologies-services-and-solutions-ar-vr-mr-xr/augmented-virtual-reality-development-integration-services-and-solutions" target="_Blank">AR & VR app development company</Link> that delivers breakthrough innovation.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    )
}
