import React, { useRef } from 'react' 
import { XRBanner } from './XRBanner';
import { XRservice } from './XRService';
import { XRBenefits } from './XRBenefits';
import { AboutXR } from './AboutXR';
import ContactUs from '../../../../../components/ContactUs/ContactUs'
import Header from '../../../../../components/Header/Header'
import Footer from '../../../../../components/Footer/Footer'
import { XRUses } from './XRUses';

export const XRTechService = () => {
  
  const portfolioRef = useRef(null);
  const contactUsRef = useRef(null);

  const scrollToRefs = {
    portfolioRef,
    contactUsRef,
  };
 
  return (
    <>
      <Header scrollToRefs={scrollToRefs} portfolio={true} getInTouch={true}/>
      <XRBanner/>
      <AboutXR />
      <XRUses/>
      <XRservice />
      <XRBenefits />
      <div ref={contactUsRef}>
      <ContactUs />
      </div>
      <Footer scrollToRefs={scrollToRefs}/>
    </>
  )
}
