import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const service1 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/Icons/ARVRXR-game-service01.png'
const service2 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/Icons/ARVRXR-game-service02.png'
const service3 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/Icons/ARVRXR-game-service03.png'
const service4 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/Icons/ARVRXR-game-service04.png'
const service5 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/Icons/ARVRXR-game-service05.png'
const service6 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/Icons/ARVRXR-game-service06.png'


export const ARVRXRservice = () => {
    return (
        <>
            <div className="ServiceContainer mt-5 mb-5">
                <div className="ServiceContent mt-4 mb-4">
                    <Container data-aos="fade-up">
                        <h1 className="fontWeight-800">
                            Game Development Services & Solutions
                        </h1>
                        <p className="text-center text-grey-500 fontSize-20 ">Here are the advanced Interactive <Link to="/" target='_blank'>custom game development services</Link> ,tailored to accomplish your business needs swiftly and effectively. </p>

                        <Row className="g-4">
                            <Col sm={12} md={4} lg={4} className='animationcol'>
                                <div className="BenefitContentMeta">
                                    <div className="vstack gap-3 align-items-center p-3">
                                        <img src={service1} className="img-fluid serviceIcon" alt="ARVRXRService-01-futurristic" />
                                        <h5 className="fontWeight-500 text-center">AR/VR/XR Game Consultation </h5>
                                        <p className="text-grey-500 text-center">Start your next game development project by getting a proper consultation from Futurristic’s expert game development crew. Clear your doubts and find out ways to craft your game within your budget with us.</p>
                                    </div>
                                </div>
                                <div className='BenefitContentMetashadow'></div>
                            </Col>
                            <Col sm={12} md={4} lg={4} className='animationcol'>
                                <div className="BenefitContentMeta">
                                    <div className="vstack gap-3 align-items-center p-3">
                                        <img src={service2} className="img-fluid serviceIcon" alt="ARVRXRService-02-futurristic" />
                                        <h5 className="fontWeight-500 text-center">Development and Design  </h5>
                                        <p className="text-grey-500 text-center">Develop your game from the game development services & solutions providers. Ensure engaging storytelling, prototyping, art & asset creation, and advanced features that meet your unique vision & expectations.</p>
                                    </div>
                                </div>
                                <div className='BenefitContentMetashadow'></div>
                            </Col>
                            <Col sm={12} md={4} lg={4} className='animationcol'>
                                <div className="BenefitContentMeta">
                                    <div className="vstack gap-3 align-items-center p-3">
                                        <img src={service3} className="img-fluid serviceIcon" alt="ARVRXRService-03-futurristic" />
                                        <h5 className="fontWeight-500 text-center">Technical Implementation  </h5>
                                        <p className="text-grey-500 text-center">Integrate your game seamlessly across multiple platforms, ensuring strong compatibility & optimized performance. Incorporate advanced technologies like AI, Spatial computing, or leverage AR/VR/XR game development services to enhance immersion & gameplay.
                                        </p>
                                    </div>
                                </div>
                                <div className='BenefitContentMetashadow'></div>
                            </Col>
                         
                            <Col sm={12} md={4} lg={4} className='animationcol'>
                                <div className="BenefitContentMeta">
                                    <div className="vstack gap-3 align-items-center p-3">
                                        <img src={service4} className="img-fluid serviceIcon" alt="ARVRXRService-04-futurristic" />
                                        <h5 className="fontWeight-500 text-center">UX & UI for Game</h5>
                                        <p className="text-grey-500 text-center">Let your game development partner Futurristic design the perfect user experience & interface for your dream game. Add intuitive navigation, visually appealing interfaces, and immersive interactions for your gamers. </p>
                                    </div>
                                </div>
                                <div className='BenefitContentMetashadow'></div>
                            </Col>
                            <Col sm={12} md={4} lg={4} className='animationcol'>
                                <div className="BenefitContentMeta">
                                    <div className="vstack gap-3 align-items-center p-3">
                                        <img src={service5} className="img-fluid serviceIcon" alt="ARVRXRService-05-futurristic" />
                                        <h5 className="fontWeight-500 text-center">Testing and Quality Assurance  </h5>
                                        <p className="text-grey-500">Test your game from all corners to ensure quality delivery and seamless performance across diverse platforms. Optimize performance to guarantee an immersive and glitch-free gaming experience that outshines.
                                        </p>
                                    </div>
                                </div>
                                <div className='BenefitContentMetashadow'></div>
                            </Col>
                            <Col sm={12} md={4} lg={4} className='animationcol'>
                                <div className="BenefitContentMeta">
                                    <div className="vstack gap-3 align-items-center p-3">
                                        <img src={service6} className="img-fluid serviceIcon" alt="ARVRXRService-06-futurristic" />
                                        <h5 className="fontWeight-500 text-center">Support and Maintenance  </h5>
                                        <p className="text-grey-500">With Futurristic’s tech gurus, get the post-launch support & maintenance services necessary to ensure the ongoing functionality, optimization, and updates of your games that enhance longevity & user satisfaction.
                                        </p>
                                    </div>
                                </div>
                                <div className='BenefitContentMetashadow'></div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}
