import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const service1 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/ImmersiveTech/XRService-01.png'
const service2 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/ImmersiveTech/XRService-02.png'
const service3 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/ImmersiveTech/XRService-03.png'
const service4 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/ImmersiveTech/XRService-04.png'
const service5 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/ImmersiveTech/XRService-05.png'
const service6 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/Icons/ARVRXR-game-service04.png' 


export const XRservice = () => {
    return (
        <>
            <Container className='my-5' data-aos="fade-up">
                <Row>
                    <Col>
                        <h1 className="fontWeight-800">
                           Extended Reality Development Services & Solutions
                        </h1>
                        <p className="text-center text-grey-500 fontSize-20">Futurristic offers the ideal Extended Reality development service and solutions your business needs. Explore now.</p>
                    </Col>
                </Row>

                <Row className="g-4">
                    <Col sm={12} md={4} lg={4} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src={service1} className="img-fluid serviceIcon" alt="XRService-01-futurristic" />
                                <h5 className="fontWeight-800 text-center">XR Strategy & Advisory Services</h5>
                                <p className="text-grey-500">Assess XR readiness, identify meaningful use cases, develop a strategic roadmap, and measure ROI for seamless integration and business growth.
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                    <Col sm={12} md={4} lg={4} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src={service2} className="img-fluid serviceIcon" alt="XRService-02-futurristic" />
                                <h5 className="fontWeight-800 text-center">Content Creation and Development</h5>
                                <p className="text-grey-500">Craft hyper-realistic 3D models, animations, and interactive XR experiences. Optimize AR/VR applications for seamless performance across devices and platforms.
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                    <Col sm={12} md={4} lg={4} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src={service3} className="img-fluid serviceIcon" alt="XRService-03-futurristic" />
                                <h5 className="fontWeight-800 text-center">Technology XR Integration & Deployment</h5>
                                <p className="text-grey-500">Choose the right XR platform, integrate it with enterprise systems, and deploy it with precision. Ensure security, data protection, and ongoing maintenance for optimal performance.
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>

                    <Col sm={12} md={4} lg={4} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src={service4} className="img-fluid serviceIcon" alt="XRService-04-futurristic" />
                                <h5 className="fontWeight-800 text-center"> Seamless XR Onboarding & Assistance  </h5>
                                <p className="text-grey-500">Ensure a smooth onboarding experience with hands-on user training, dedicated technical support, and regular content updates. Ensure XR experiences are accurate, functional, and up-to-date.
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col >
                    <Col sm={12} md={4} lg={4} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src={service5} className="img-fluid serviceIcon" alt="XRService-05-futurristic" />
                                <h5 className="fontWeight-800 text-center">Emerging XR Technology Solutions  </h5>
                                <p className="text-grey-500">Enhance operations with Digital Twins, optimize workflows with XR collaboration, and create immersive Metaverse experiences for training, simulations, and engagement.
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                    <Col sm={12} md={4} lg={4} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src={service6} className="img-fluid serviceIcon" alt="XRService-06-futurristic" />
                                <h5 className="fontWeight-800 text-center"> XR Customization & Enhancement</h5>
                                <p className="text-grey-500">
                                Expand XR capabilities with tailored solutions, including adaptive interfaces, gamification, and advanced haptic feedback. Enable industry-specific modifications, and future-ready innovations for enhanced experiences.
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
