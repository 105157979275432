import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const service1 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/ImmersiveTech/ARVRService-01.png'
const service2 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/ImmersiveTech/ARVRService-02.png'
const service3 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/ImmersiveTech/ARVRService-03.png'
const service4 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/ImmersiveTech/ARVRService-04.png'
const service5 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/ImmersiveTech/ARVRService-05.png'
const service6 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/ImmersiveTech/ARVRService-06.png' 


export const ARVRservice = () => {
    return (
        <>
            <Container className='my-5' data-aos="fade-up">
                <Row>
                    <Col>
                        <h1 className="fontWeight-800">
                            Augmented Virtual Reality Development Integration Services & Solutions
                        </h1>
                        <p className="text-center text-grey-500 fontSize-20">Find comprehensive, professional AR-VR solutions that are tailored for seamless business transformation improvising immersive experiences effectively. </p>
                    </Col>
                </Row>

                <Row className="g-4">
                    <Col sm={12} md={4} lg={4} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src={service1} className="img-fluid serviceIcon" alt="ARVRService-01-futurristic" />
                                <h5 className="fontWeight-800 text-center">AR/VR Consultation & Strategy Services</h5>
                                <p className=" text-grey-500">Get customized strategies from the best <Link to="/" target="_Blank">AR & VR App Development Company</Link>. Explore innovative solutions that drive engagement, improve learning outcomes, and transform industry user experience.
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                    <Col sm={12} md={4} lg={4} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src={service2} className="img-fluid serviceIcon" alt="ARVRService-02-futurristic" />
                                <h5 className="fontWeight-800 text-center">AR/VR Training & Education</h5>
                                <p className=" text-grey-500">Enhance learning experiences with immersive AR/VR training solutions. Create engaging, interactive environments that facilitate knowledge retention & skill development, empowering educators & learners alike.</p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                    <Col sm={12} md={4} lg={4} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src={service3} className="img-fluid serviceIcon" alt="ARVRService-03-futurristic" />
                                <h5 className="fontWeight-800 text-center">AR VR App/Software Development </h5>
                                <p className=" text-grey-500">Create custom AR/VR applications with a robust backend infrastructure and intuitive user interfaces. Ensure seamless navigation and optimal performance, delivering a superior experience for all.
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>

                    <Col sm={12} md={4} lg={4} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src={service4} className="img-fluid serviceIcon" alt="ARVRService-04-futurristic" />
                                <h5 className="fontWeight-800 text-center">AR VR Integration</h5>
                                <p className=" text-grey-500">Seamlessly integrate AR/VR into your existing systems to enhance user interaction & data visualization. Ensure your applications are enriched with immersive experiences that drive engagement and productivity.
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col >
                    <Col sm={12} md={4} lg={4} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src={service5} className="img-fluid serviceIcon" alt="ARVRService-05-futurristic" />
                                <h5 className="fontWeight-800 text-center">AR VR Content Creation</h5>
                                <p className=" text-grey-500">Develop AR and VR experiences using expert storyboarding, 360° video and imagery, photogrammetry, and 3D modeling. Enhance immersion with spatial audio, technical art, and intuitive UI/UX design.</p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                    <Col sm={12} md={4} lg={4} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src={service6} className="img-fluid serviceIcon" alt="ARVRService-06-futurristic" />
                                <h5 className="fontWeight-800 text-center">Virtual Showrooms & Product Demos </h5>
                                <p className=" text-grey-500">
                                    Transform the customer experience with virtual showrooms where users can explore products in an interactive environment. Get the ideal solutions to effectively showcase your offering, increase customer engagement, and drive sales.
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
