import React, { useRef } from 'react' 
import { MRBanner } from './MRBanner';
import { AboutMR } from './AboutMR';
import { MRService } from './MRService';
import { MRBenefits } from './MRBenefits';
import ContactUs from '../../../../../components/ContactUs/ContactUs'
import Header from '../../../../../components/Header/Header'
import Footer from '../../../../../components/Footer/Footer'
import { MRUses } from './MRUses';

export const MRTechService = () => {
  
  const portfolioRef = useRef(null);
  const contactUsRef = useRef(null);

  const scrollToRefs = {
    portfolioRef,
    contactUsRef,
  };
 
  return (
    <>
      <Header scrollToRefs={scrollToRefs} portfolio={true} getInTouch={true}/>
      <MRBanner/>
      <AboutMR />
      <MRUses/>
      <MRService />
      <MRBenefits />
      <div ref={contactUsRef}>
      <ContactUs />
      </div>
      <Footer scrollToRefs={scrollToRefs}/>
    </>
  )
}
