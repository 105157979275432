import './App.css';
import './assets/css/Style.css'
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import LandingPage from './landingPage/LandingPage';
import { MetaServices } from './landingPage/ServicesPages/ServiceMeta';
import { WebARServices } from './landingPage/ServicesPages/ServiceAR';
import { BlockChain } from './landingPage/ServicesPages/BlockchainService';
// import { ARVRTraning } from './landingPage/ServicesPages/ARVREducation';
import { ARVRXRGame } from './landingPage/ServicesPages/ARVRXRGame';
import { MobileApp } from './landingPage/ServicesPages/UIUXMobileApp';
import { PrivacyPolicy } from './components/PrivacyPolicy';
import { TermCondition } from './components/Term&Conditions';
import { Helmet } from 'react-helmet';
import { Blogs } from './Blogs';
import CollabSuite from './Collab_Suite';
import { BlogDetail } from './Blogs/BlogCardDetails/BlogDetail';
import { ARVRMRXRTech } from './landingPage/ServicesPages/ARVRMRXRTechService';
import { ARVRTechService } from './landingPage/ServicesPages/ARVRMRXRTechService/TechServices/ARVRTechService/ARVRService';
import { XRTechService } from './landingPage/ServicesPages/ARVRMRXRTechService/TechServices/XRTechService';
import { MRTechService } from './landingPage/ServicesPages/ARVRMRXRTechService/TechServices/MRTechService';

function App() {
  // Helper Component for SEO Metadata
  const RouteSpecificMeta = ({ title, description, pageURL }) => (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={pageURL} />
    </Helmet>
  );

  // Individual Components with Meta
  const LandingPageWithMeta = () => (
    <>
      <RouteSpecificMeta title="Futurristic | Futurristic Business Solutions " description="Get solutions- Vision Pro/MetaQuest/Oculus app, Augmented/Virtual/Mixed/Extended reality, WebAR, Metaverse, blockchain, game, mobile-web-enterprise development." pageURL='https://futurristic.com/' />
      <LandingPage />
    </>
  );

  const MetaServicesWithMeta = () => (
    <>
      <RouteSpecificMeta title="Metaverse Development | Metaverse Services & Solutions | Metaverse End-to-end Services & Solutions" description="Explore Metaverse consulting development, integration, strategy, & enterprise services & solutions. Offering NFT solutions, in-built games & asset makers." pageURL='https://futurristic.com/metaverse-end-to-end-solutions' />
      <MetaServices />
    </>
  );

  const WebARServicesWithMeta = () => (
    <>
      <RouteSpecificMeta title="Web AR Development and Marketing Solutions | Web Based AR" description="Elevate your marketing efforts with WebAR development. Launch Web-based augmented reality campaigns. Achieve more with WebAR marketing/business solutions! " pageURL='https://futurristic.com/web-ar-based-marketing-solutions' />
      <WebARServices />
    </>
  );

  const BlockChainWithMeta = () => (
    <>
      <RouteSpecificMeta title="Blockchain Integration and Solutions  | Blockchain Integration and Development Services" description="Get expert consultation on custom blockchain integration & development. Ensure streamlined operations & enhanced security measures." pageURL='https://futurristic.com/blockchain-integration-and-solutions' />
      <BlockChain />
    </>
  );


  const ARVRMRXRWithMeta = () => (
    <>
      <RouteSpecificMeta title="Immersive Technologies Services and Solutions AR VR MR XR " description="Immersive technology services & solutions, augmented virtual mixed extended reality AR/VR/XR/MR, Vision Pro app development,Microsoft HoloLens,Oculus,Meta Quest" pageURL='https://futurristic.com/immersive-technologies-services-and-solutions-ar-vr-mr-xr' />
        <ARVRMRXRTech />
    </>
  );

  const ARVRTechServiceWithMeta = () => (
    <>
      <RouteSpecificMeta title="Augmented Virtual Reality Development Integration Services & Solutions" description="Augmented & Virtual Reality AR VR training, application development, and integration services & solutions. Sales/marketing solutions.Meta Quest Apple Vision Pro" pageURL='https://futurristic.com/immersive-technologies-services-and-solutions-ar-vr-mr-xr/augmented-virtual-reality-development-integration-services-and-solutions' />
      <ARVRTechService/>
    </>
  )

  const XRTechServiceWithMeta = () => (
    <>
      <RouteSpecificMeta title="Custom Extended Reality Development Services and Solutions (XR)" description="Extended Reality enterprise services XR business/marketing solutions. Training,collaboration, simulation.Apple Vision Pro,Quest 3,HoloLens 2,Unreal Engine,Unity" pageURL='https://futurristic.com/immersive-technologies-services-and-solutions-ar-vr-mr-xr/extended-reality-development-services-and-solutions-xr' />
      <XRTechService/>
    </>
  )

  const MRTechServiceWithMeta = () => (
    <>
      <RouteSpecificMeta title="Custom Mixed Reality MR Development Services and Solutions" description="Mixed Reality MR enterprise applications app development services solutions Remote/virtual training and collaboration,Apple Vision Pro,Quest 3,Quest Pro,HoloLens" pageURL='https://futurristic.com/immersive-technologies-services-and-solutions-ar-vr-mr-xr/mixed-reality-development-services-and-solutions-mr"' />
      <MRTechService/>
    </>
  )


  const ARVRXRGameWithMeta = () => (
    <>
      <RouteSpecificMeta title="Game Development Services & Solutions | AR/VR/XR Game Design & Development Services" description="AR VR XR/Unity/PC/Mobile game design and development services & solutions. Integrate monetization, AR/VR/XR features, strategy, and enterprise solutions." pageURL='https://futurristic.com/game-development-services-&-solutions' />
      <ARVRXRGame />
    </>
  );

  const MobileAppWithMeta = () => (
    <>
      <RouteSpecificMeta title="Mobile Web & Enterprise Development Services" description="Get custom mobile & web applications and enterprise platform development/integration services & solutions. App/software development company. eCommerce, SaaS." pageURL='https://futurristic.com/mobile-web-&-enterprise-development' />
      <MobileApp />
    </>
  );



  const PrivacyPolicyWithMeta = () => (
    <>
      <RouteSpecificMeta title="Privacy Policy" description="Read our privacy policy." pageURL='https://futurristic.com/privacypolicy' />
      <PrivacyPolicy />
    </>
  );
 

  const TermConditionWithMeta = () => (
    <>
      <RouteSpecificMeta title="Terms and Conditions" description="Read our terms and conditions." pageURL='https://futurristic.com/termsandconditions' />
      <TermCondition />
    </>
  );
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPageWithMeta />} />
          <Route path="/metaverse-end-to-end-solutions" element={<MetaServicesWithMeta />} />
          <Route path="/web-ar-based-marketing-solutions" element={<WebARServicesWithMeta />} />
          <Route path="/blockchain-integration-and-solutions" element={<BlockChainWithMeta />} />
          
          <Route path="/immersive-technologies-services-and-solutions-ar-vr-mr-xr" element={<ARVRMRXRWithMeta />} >
          <Route path="augmented-virtual-reality-development-integration-services-and-solutions" element={<ARVRTechServiceWithMeta />} /> 
          <Route path="extended-reality-development-services-and-solutions-xr" element={<XRTechServiceWithMeta />} /> 
          <Route path="mixed-reality-development-services-and-solutions-mr" element={<MRTechServiceWithMeta />} />
          </Route> 
          <Route path="/game-development-services-&-solutions" element={<ARVRXRGameWithMeta />} />
          <Route path="/mobile-web-&-enterprise-development" element={<MobileAppWithMeta />} />
          <Route path="/privacypolicy" element={<PrivacyPolicyWithMeta />} />
          <Route path="/termsandconditions" element={<TermConditionWithMeta />} />
          <Route path='/collab-suite' element={<CollabSuite />} />
          <Route path='/insights' element={<Blogs />} />
          <Route path='/insights/:url' element={<BlogDetail />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

