import React, { useRef } from 'react'
import { ARVRBanner } from './ARVRBanner';
import Footer from '../../../../../../components/Footer/Footer';
import Header from '../../../../../../components/Header/Header';
import ContactUs from '../../../../../../components/ContactUs/ContactUs';
import { AboutARVR } from './AboutARVR';
import { ARVRservice } from './ARVRService';
import { BenefitARVR } from './BenefitARVR';
import { Outlet } from 'react-router-dom';
import { ARVRUses } from './ARVRUses';

export const ARVRTechService = () => {
  
  const portfolioRef = useRef(null);
  const contactUsRef = useRef(null);

  const scrollToRefs = {
    portfolioRef,
    contactUsRef,
  };
 
  return (
    <>
      <Header scrollToRefs={scrollToRefs} portfolio={true} getInTouch={true}/>
      <ARVRBanner/>
      <AboutARVR />
      <ARVRUses />
      <ARVRservice />
      <BenefitARVR />
      {/* <ARVRUses /> */}
      {/* <ARVRTool /> */}
      {/* <div ref={portfolioRef}>
      <ARVRPortfolio />
      </div> */}
      <div ref={contactUsRef}>
      <ContactUs />
      </div>
      <Footer scrollToRefs={scrollToRefs}/>
       <Outlet/>
    </>
  )
}
