import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const service1 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/Icons/ARVRTraining-service01.png'
const service2 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/Icons/ARVRTraining-service02.png'
const service3 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/Icons/ARVRTraining-service03.png' 


export const TechService = () => {
    return (
        <>
            <Container className='my-5' data-aos="fade-up">
                <Row>
                    <Col>
                        <h1 className="fontWeight-800">
                        Immersive Technologies Services and Solutions
                        </h1>
                        <p className="text-center text-grey-500 fontSize-20">Discover these 3 dynamic services under the umbrella of immersive tech solutions that will propel your business forward, delight users, and drive digital transformation.</p>
                    </Col>
                </Row>

                <Row className="g-4">
                    <Col sm={12} md={4} lg={4} className='animationcol'>
                        <div className="shadow py-3 TechService rounded-4">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src={service1} className="img-fluid serviceIcon" alt="ARVRService-01-futurristic" />
                                <h5 className="fontWeight-800 text-center">Augmented/Virtual Reality Services (AR/VR) Solutions </h5>
                                <p className="text-grey-500">Improve customer engagement with interactive, 3D-driven experiences. Bring products to life, offer virtual tours and try-ons, AR filters, and interactive brand storytelling, similar to Snapchat’s AR lenses.</p>
                                <Link to='augmented-virtual-reality-development-integration-services-and-solutions' target='_blank'>
                                    <Button className='rounded-5 ContactBtn  mobile-100' variant='dark' type='submit'>Learn More</Button>
                                </Link>
                            </div>
                        </div>
                         
                    </Col>
                    <Col sm={12} md={4} lg={4} className='animationcol'>
                        <div className="shadow py-3 TechService rounded-4">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src={service2} className="img-fluid serviceIcon" alt="ARVRService-02-futurristic" />
                                <h5 className="fontWeight-800 text-center">Mixed Reality Development Services & Solutions (MR)</h5>
                                <p className="text-grey-500">Blend digital & physical worlds with MR solutions such as HoloLens. Enable interactive learning, real-time collaboration and hands-free data visualization for healthcare & manufacturing.</p>
                                <Link to='mixed-reality-development-services-and-solutions-mr' target='_blank'>
                                    <Button className='rounded-5 ContactBtn  mobile-100' variant='dark' type='submit'>Learn More</Button>
                                </Link>
                            </div>
                        </div>
                         
                    </Col>
                    <Col sm={12} md={4} lg={4} className='animationcol'>
                        <div className="shadow py-3 TechService rounded-4">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img src={service3} className="img-fluid serviceIcon" alt="ARVRService-03-futurristic" />
                                <h5 className="fontWeight-800 text-center">Extended Reality Development Services & Solutions (XR)</h5>
                                <p className="text-grey-500">Go beyond AR and VR with XR-powered solutions for retail, gaming, and enterprise training. Deliver fully immersive, multi-sensory experiences that redefine the way users interact with content and services.</p>
                                <Link to='extended-reality-development-services-and-solutions-xr' target='_blank'>
                                    <Button className='rounded-5 ContactBtn  mobile-100' variant='dark' type='submit'>Learn More</Button>
                                </Link>
                            </div>
                        </div>
                         
                    </Col>
                </Row>
            </Container>
        </>
    )
}
