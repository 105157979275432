import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const AboutTech = () => {
    return (
        <>
            <Container className='my-5' data-aos="fade-up">
                <div className="AboutUs02">
                    <Row className="justify-content-center">
                        <Col md={12} lg={9} sm={12}>
                            <div className="AboutUsText">
                                <div className="vstack gap-3">
                                    <h1 className="text-center fontWeight-800">Escape Reality—Your Immersive Tech Solutions Provider is Here </h1>
                                    <h4 className="text-center">Next-Gen AR/VR/XR Solutions for 2025 and Beyond </h4>
                                    <p className="text-center fontSize-24 text-grey-500 fontWeight-500">
                                    Immerse yourself in a world of wonder by leveraging immersive technology services & solutions. Whether augmented reality, virtual reality, mixed reality or a complete package covering augmented reality, it all falls under the immersive technology umbrella. Develop cutting-edge applications tailored to different industries and tackle key challenges to improve interaction with your customers and create a deeper connection with your audience. Integrate <Link to='/web-ar-based-marketing-solutions' target='_Blank'>Web-ar based marketing solutions</Link> web-based marketing solutions for seamless, web-accessible augmented experiences that make customer interactions more dynamic and impactful.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    )
}
