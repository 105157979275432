import React from 'react'
import { Container, Row, Col, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const benefit1 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/ImmersiveTech/ARVRMRXRTechServices-benefit01.png'
const benefit2 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/ImmersiveTech/ARVRMRXRTechServices-benefit02.png'
const benefit3 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/ImmersiveTech/ARVRMRXRTechServices-benefit03.png'
const benefit4 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/ImmersiveTech/ARVRMRXRTechServices-benefit04.png'
const benefit5 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/ImmersiveTech/ARVRMRXRTechServices-benefit05.png'
const benefit6 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/ImmersiveTech/ARVRMRXRTechServices-benefit06.png'


export const TechBenefits = () => {
    return (
        <>
            <Container className="my-5" data-aos="fade-up">
                <Row>
                    <Col >
                        <h1 className="text-center fontWeight-800">                
                         Here are the Potential Benefits of Immersive Technologies Services and Solutions
                        </h1>
                        <p className="text-center text-grey-500 fontSize-20 ">
                        Discover how an <Link to="/" target="_Blank"> Immersive technology solutions provider</Link> can help optimize your business operations and create unforgettable experiences.
                        </p>
                    </Col>
                </Row>
                <Row className="g-3">
                    <Col md={4} xs={6} lg={2}>
                        <div className="BenefitContent1">
                            <img src={benefit1} className="img-fluid benefitIcons" alt="ARVRBenefit-01-futurristic" />
                            <Stack direction='horizontal' className='align-items-center justify-content-center h-50'><p>Improved Collaboration </p></Stack>
                        </div>
                    </Col>
                    <Col md={4} xs={6} lg={2}>
                        <div className="BenefitContent2">
                            <img src={benefit2} className="img-fluid benefitIcons" alt="ARVRBenefit-02-futurristic" />
                            <Stack direction='horizontal' className='align-items-center justify-content-center h-50'><p>Better Product Visualization </p>
                            </Stack>
                        </div>
                    </Col>
                    <Col md={4} xs={6} lg={2}>
                        <div className="BenefitContent1">
                            <img src={benefit3} className="img-fluid benefitIcons" alt="ARVRBenefit-03-futurristic" />
                            <Stack direction='horizontal' className='align-items-center justify-content-center h-50'><p>Customer Experiences</p></Stack>
                        </div>
                    </Col>
                    <Col md={4} xs={6} lg={2}>
                        <div className="BenefitContent2">
                            <img src={benefit4} className="img-fluid benefitIcons" alt="ARVRBenefit-04-futurristic" />
                            <Stack direction='horizontal' className='align-items-center justify-content-center h-50'><p>Enhanced Training & Learning </p></Stack>
                        </div>
                    </Col>
                    <Col md={4} xs={6} lg={2}>
                        <div className="BenefitContent1">
                            <img src={benefit5} className="img-fluid benefitIcons" alt="ARVRBenefit-05-futurristic" />
                            <Stack direction='horizontal' className='align-items-center justify-content-center h-50'><p>Cost-Effective Solution </p></Stack>
                        </div>
                    </Col>
                    <Col md={4} xs={6} lg={2}>
                        <div className="BenefitContent2">
                            <img src={benefit6} className="img-fluid benefitIcons" alt="ARVRBenefit-06-futurristic" />
                            <Stack direction='horizontal' className='align-items-center justify-content-center h-50'><p>Increased Productivity</p></Stack>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
