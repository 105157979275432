import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const service1 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/Icons/MobileWeb-service01.png'
const service2 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/Icons/MobileWeb-service02.png'
const service3 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/Icons/MobileWeb-service03.png'
const service4 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/Icons/MobileWeb-service04.png'
const service5 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/Icons/MobileWeb-service05.png'
const service6 = 'https://futurristic-prod.s3.ap-south-1.amazonaws.com/image/video/Icons/MobileWeb-service06.png'


export const MobileAppservice = () => {
    return (
        <>
            <Container className='my-5'>
                <Row>
                    <Col>
                        <h1 className="fontWeight-800">
                            Mobile, Web & Enterprise Development Services
                        </h1>
                        <p className="text-center text-grey-500 fontSize-20 ">Dive into the various mobile web & enterprise development services and get started with the perfect, ideal solutions your business needs. </p>
                    </Col>
                </Row>
                <Row className="g-3">
                    <Col lg={4} md={6} sm={12} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img
                                    src={service1}
                                    className="img-fluid serviceIcon"
                                    alt="MobileApp-service-01-futurristic"
                                />
                                <h5 className="fontWeight-500 text-center">
                                    Consultation & Strategy
                                </h5>
                                <p className="text-grey-500">
                                    Take expert guidance from Futurristic’s adepts in <Link to="/">mobile web & enterprise development services</Link> with years of expertise & experience. Consult regarding mobile web & enterprise dev-solution, enterprise mobility solutions, and data migration & integration.
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                    <Col lg={4} md={6} sm={12} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img
                                    src={service2}
                                    className="img-fluid serviceIcon"
                                    alt="MobileApp-service-02-futurristic"
                                />
                                <h5 className="fontWeight-500 text-center">Development & Design</h5>
                                <p className="text-grey-500">
                                    Build your custom mobile app with Futurristic, encompassing enterprise web application development, responsive web design, cross-platform mobile development, and progressive web app (PWA) solutions to meet all your business needs.
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                    <Col lg={4} md={6} sm={12} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img
                                    src={service3}
                                    className="img-fluid serviceIcon"
                                    alt="MobileApp-service-03-futurristic"
                                />
                                <h5 className="fontWeight-500 text-center">Integration & Backend Services</h5>
                                <p className="text-grey-500">
                                    From Backend & API Development to Cloud Integration Services and Enterprise System Integration, make your business model/platform competitive with such integrations. Add advanced features and functionalities with ease and efficiency.
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                    <Col lg={4} md={6} sm={12} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img
                                    src={service4}
                                    className="img-fluid serviceIcon"
                                    alt="MobileApp-service-04-futurristic"
                                />
                                <h5 className="fontWeight-500 text-center">User Experience & Interface</h5>
                                <p className="text-grey-500">
                                    Design your mobile, web, or entrepreneurial platform with intuitive navigation, compelling, and responsive design. Ensure quick load times, usability by following WCAG guidelines, scalability, and seamless user interaction altogMobileApp-service-01-futurristicether
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                    <Col lg={4} md={6} sm={12} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img
                                    src={service5}
                                    className="img-fluid serviceIcon"
                                    alt="MobileApp-service-05-futurristic"
                                />
                                <h5 className="fontWeight-500 text-center">Testing, Maintenance, & Security</h5>
                                <p className="text-grey-500">
                                    Ensure robust performance complete, Mobile application testing & QA, Application maintenance & support, Mobile security solutions, and Performance optimization services for seamless, secure, and scalable operation.
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                    <Col lg={4} md={6} sm={12} className='animationcol'>
                        <div className="BenefitContentMeta">
                            <div className="vstack gap-3 align-items-center p-3">
                                <img
                                    src={service6}
                                    className="img-fluid serviceIcon"
                                    alt="MobileApp-service-06-futurristic"
                                />
                                <h5 className="fontWeight-500 text-center">Advanced Solutions & Support</h5>
                                <p className="text-grey-500">
                                    Get advanced solutions & support to strengthen your business. Services include E-commerce development, Business Intelligence & Analytics Solutions, DevOps & Continuous Integration/Continuous Deployment (CI/CD), and AR/VR integration.
                                </p>
                            </div>
                        </div>
                        <div className='BenefitContentMetashadow'></div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
